import React from 'react';
import { Link } from 'gatsby';
import Image from 'reusecore/src/elements/Image';
import DocumentationSection from '../../../containers/SaasModern/Documentation';
import insert_legend_1 from '../../../../../common/src/assets/image/insert_legend_1.png';
import insert_legend_2 from '../../../../../common/src/assets/image/insert_legend_2.png';
import insert_legend_3 from '../../../../../common/src/assets/image/insert_legend_3.png';
import insert_legend_4 from '../../../../../common/src/assets/image/insert_legend_4.png';
import insert_legend_5 from '../../../../../common/src/assets/image/insert_legend_5.png';
import insert_category_legend_1 from '../../../../../common/src/assets/image/insert_category_legend_1.png';
import categories_inserted from '../../../../../common/src/assets/image/2019/categories_inserted.png';
import legend_example from '../../../../../common/src/assets/image/legend_example.png';
import legend_customize from '../../../../../common/src/assets/image/2019/legend_customize.png';

const Documentation = () => {
  const content = (
    <div>
      <p>
        PrintableCal can{' '}
        <Link to="/Documentation/Create-Calendar/Templates-and-Options">
          create calendars
        </Link>{' '}
        containing data from{' '}
        <Link to="/Documentation/Create-Calendar/Calendar-Data-Sources">
          multiple sources
        </Link>
        , all overlaid into the same page and color-coded based on the
        associated calendar data sources or{' '}
        <Link to="/Documentation/Options/Category-Colors">categories</Link>. If
        your calendar contains data from a variety of sources, it can be helpful
        to insert a legend to show the color-coding.
      </p>
      <p>
        To insert a legend, click the <strong>Legend </strong>button on the
        PrintableCal tab of the Excel, Word, or PowerPoint ribbon.
        <br />
        <Image alt="" src={insert_legend_1} style={{ width: 689 }} />
      </p>
      <p>
        The <strong>Insert Legend</strong> Task Pane will appear after clicking
        the <strong>Legend </strong>button. The most recently used calendar data
        sources will be selected by default. If using Excel, you can choose to
        insert the legend as a picture or as formatted cells starting at the
        currently selected cell. In Word and PowerPoint, the legend will always
        insert as a picture. After inserting the legend as a picture, it can be
        dragged to the desired location or resized as needed.
        <br />
        <Image alt="" src={insert_legend_2} />
      </p>
      <p>
        You can also pick the maximum number of columns to use for the legend.
        For example, if you have 6 calendar data sources and you pick 3 columns,
        the legend will generate as a grid consisting of 2 rows and 3 columns.
        For a vertical legend, pick 1 as the maximum number of columns. For a
        horizontal legend, set the maximum number of columns to the same as the
        number of selected calendar data sources.
        <br />
        <Image alt="" src={insert_legend_3} style={{ width: 449 }} />
      </p>
      <p>
        The fill and border color of the legend can also be specified. <br />
        <Image alt="" src={insert_legend_4} style={{ width: 447 }} />
      </p>
      <p>
        Here's an example where the background color is set to gray with a
        darker gray border.
        <br />
        <Image alt="" src={insert_legend_5} style={{ width: 546 }} />
      </p>
      <h2>Insert a Category Legend</h2>
      <p>
        Legends can be inserted using either calendar colors or category colors.
        To insert a category legend, select <strong>Categories </strong>from the{' '}
        <strong>Legend type</strong> drop-down.
      </p>
      <p>
        <Image alt="" src={insert_category_legend_1} style={{ width: 448 }} />
      </p>
      <p>
        Category names and colors will be automatically imported when generating
        calendars with data from Outlook, Exchange Server, or VueMinder.
        Categories can also be defined in the{' '}
        <Link to="/Documentation/Options">PrintableCal Options</Link> or by
        clicking the <strong>New category</strong> button under{' '}
        <strong>Select categories</strong>.
      </p>
      <p>
        <Image alt="" src={categories_inserted} style={{ width: 874 }} />
      </p>
      <h2>Automatically Generate a Legend</h2>
      <p>
        PrintableCal can automatically generate a legend when creating a
        calendar. Most templates will include an automatic legend in their
        output. When a legend is automatically generated, it will show any
        category or calendar data source colors that are visible in the
        generated calendar. If there are no items associated with a particular
        category or calendar data source, that category or calendar data source
        will not be included in the automatically generated legend.
      </p>
      <p>
        <Image alt="" src={legend_example} />
      </p>
      <p>
        To include a legend when{' '}
        <Link to="/Documentation/How-To/Customize-a-Template">
          customizing a template
        </Link>
        , name a cell range "Legend" where the legend should be placed. Set the
        value of the Legend cell to "Legend(Columns=X)", where X is a number
        between 1 and 10. This value controls how many columns can be shown in
        the legend before wrapping to a new row. You could set the number of
        columns based on how much space is available in the template and the
        length of your calendar or category names. Align the cell text based on
        how you want the output legend to be aligned. For example, if you want
        the legend aligned with the right edge of the cell, set the text
        alignment to right.
      </p>
      <p>
        <Image alt="" src={legend_customize} />
      </p>
    </div>
  );

  return (
    <DocumentationSection
      url="https://www.printablecal.com/Documentation/Insert-Legend"
      commentsId="commentsplus_post_150_489"
      title="Insert a Color-Coded Legend"
      description="PrintableCal can generate calendars from multiple sources, color-coded based on the source. A legend can be inserted into a printable calendar to show the color-coding."
      keywords="calendar legend, color-coded calendar, insert legend, color-coded events, printable calendar, calendar template, overlay calendars, Excel calendar, Word calendar, 2020 calendar"
      content={content}
    />
  );
};

export default Documentation;
